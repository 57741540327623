<template>
  <div class="tricast-buttons-grid">
    <div class="header">
      <div class="header-item-empty"></div>
      <div class="header-item"
           v-for="(item, key) in headerItems"
           :key="key">
        {{item.title}}
      </div>
    </div>
    <div class="buttons-row"
         v-for="(row, index) in $attrs.racers"
         :key="index">
      <div class="label">
        <RacerLabel :ordinalNumber="row"
                    :color="$attrs.colors[index]"
                    height="40"
                    orientation="right">
        </RacerLabel>
      </div>
      <Button v-for="(racer, key) in tricastRows[index]"
              :key="key"
              :disabled="racer.disabled || $attrs.bettingDisabled"
              @change="selectOdd(racer)"
              :variation="racer.selected ? 'brand' : 'default'"
              label="">
              <i :class="racer.label"></i>
      </Button>
    </div>
    <div class="add-bet-wrapper">
      <Button :label="translations.general_add_bet"
              :variation="checkIsValid ? 'default' : 'brand'"
              :disabled="checkIsValid"
              @change="handleChange">
      </Button>
    </div>
  </div>
</template>

<script>
import {
  times,
  each,
  reject,
  cloneDeep,
} from 'lodash';
import { mapGetters } from 'vuex';
import { Button } from '@nsftx/games-sdk-js';
import RacerLabel from '@/components/RacerLabel';

export default {
  name: 'TricastGrid',
  components: {
    RacerLabel,
    Button,
  },
  data() {
    return {
      tricastRows: null,
      selectedRacers: [],
      bettingUpdated: false,
      firstSelected: [],
      secondSelected: [],
      thirdSelected: [],
      anySelected: [],
      currentRoundOffer: null,
      parsedOffer: null,
      isValidBetSelect: null,
      currentBetData: null,
      currentEventId: null,
      firstUnique: null,
      secondUnique: null,
      thirdUnique: null,
    };
  },
  computed: {
    ...mapGetters([
      'translations',
      'offer',
      'currentRound',
      'disabledRound',
    ]),
    checkIsValid() {
      if (this.currentRound === this.disabledRound) {
        return true;
      }
      this.checkRoundOffer();
      const first = [];
      const second = [];
      const third = [];
      //  const countMap = new Map();
      let isValid = false;
      if (this.firstSelected.length !== 0
        && this.secondSelected.length !== 0 && this.thirdSelected.length !== 0
        && this.anySelected.length === 0) {
        each(this.firstSelected, (racer) => {
          first.push(racer.id);
        });
        each(this.secondSelected, (racer) => {
          second.push(racer.id);
        });
        each(this.thirdSelected, (racer) => {
          third.push(racer.id);
        });
        const hasUnique = this.hasUniqueRacers(first, second, third);
        const result = this.currentRoundOffer.filter((obj) => JSON.stringify(obj.racers)
          === JSON.stringify([
            this.firstUnique,
            this.secondUnique,
            this.thirdUnique]));
        if (!hasUnique) {
          isValid = false;
        } else {
          this.setBetData(result);
          isValid = true;
        }
        return !isValid;
      }
      if (this.anySelected.length >= 3) {
        return false;
      }
      return !(this.firstSelected.length !== 0
        && this.secondSelected !== 0 && this.thirdSelected.length !== 0 && isValid);
    },
    selected() {
      return this.$attrs.activeButtons;
    },
    headerItems() {
      return [
        {
          id: 0,
          title: this.translations.greyhound_tricast_first,
        },
        {
          id: 1,
          title: this.translations.greyhound_tricast_second,
        },
        {
          id: 2,
          title: this.translations.greyhound_tricast_third,
        },
        {
          id: 3,
          title: this.translations.greyhound_tricast_any,
        },
      ];
    },
  },
  watch: {
    bettingUpdated() {
      // eslint-disable-next-line
      const isEqual = this.selectedRacers.every(selected => selected === this.selectedRacers[0]);
      if (isEqual && this.selectedRacers[0] === false) {
        this.enableAllColumns();
      }
    },
  },
  methods: {
    hasUniqueRacers(first, second, third) {
      for (let i = 0; i < first.length; i += 1) {
        for (let j = 0; j < second.length; j += 1) {
          for (let k = 0; k < third.length; k += 1) {
            if (first[i] !== second[j] && first[i] !== third[k] && second[j] !== third[k]) {
              this.firstUnique = first[i];
              this.secondUnique = second[j];
              this.thirdUnique = third[k];
              return true;
            }
          }
        }
      }
      return false;
    },
    setBetData(data) {
      // eslint-disable-next-line
      this.currentBetData = data[0];
    },
    checkRoundOffer() {
      each(this.offer, (round) => {
        if (this.currentRound === round.eventIdToday) {
          const clone = JSON.parse(JSON.stringify(round.odds.tricast));
          this.currentRoundOffer = clone;
          this.currentEventId = round.eventId;
        }
      });
    },
    getCombinations(first, second, third) {
      const combinations = [];
      first.forEach((firstbet, j) => {
        second.forEach((secondbet, i) => {
          third.forEach((thirdbet, k) => {
            if (
              first[j] === second[i]
              || first[j] === third[k]
              || second[i] === third[k]
            ) {
              return;
            }
            combinations.push(first[j] + '-' + second[i] + '-' + third[k]);
          });
        });
      });
      return combinations.length;
    },
    getUniqueCombinations(first, second, third) {
      const combinations = [];
      first.forEach((firstbet, j) => {
        second.forEach((secondbet, i) => {
          third.forEach((thirdbet, k) => {
            if (
              first[j] === second[i]
              || first[j] === third[k]
              || second[i] === third[k]
            ) {
              return;
            }
            combinations.push(first[j] + '-' + second[i] + '-' + third[k]);
          });
        });
      });
      return combinations;
    },
    formatTricastOutcome(combinations) {
      const placements = {
        first: new Set(),
        second: new Set(),
        third: new Set(),
      };
      combinations.forEach((combination) => {
        const [first, second, third] = combination.split('-');
        placements.first.add(first);
        if (second) placements.second.add(second);
        if (third) placements.third.add(third);
      });
      const firstStr = Array.from(placements.first).sort().join(', ');
      const secondStr = Array.from(placements.second).sort().join(', ');
      const thirdStr = Array.from(placements.third).sort().join(', ');
      return `${firstStr} - ${secondStr} - ${thirdStr}`;
    },
    formatTricastLabel(combs) {
      if (combs > 1) {
        return `${this.translations.greyhound_tricast} (${this.translations.general_combinations}: ${combs})`;
      }
      return this.translations.greyhound_tricast;
    },
    handleChange() {
      const first = [];
      const second = [];
      const third = [];
      const any = [];
      each(this.firstSelected, (racer) => {
        first.push(racer.id);
      });
      each(this.secondSelected, (racer) => {
        second.push(racer.id);
      });
      each(this.thirdSelected, (racer) => {
        third.push(racer.id);
      });
      if (this.anySelected.length > 0) {
        each(this.anySelected, (racer) => {
          any.push(racer.id);
        });
      }
      this.getCombinations(first, second, third);
      const bet = {
        racers: this.anySelected.length > 0 ? any : [first.sort(), second.sort(), third.sort()],
        eventId: this.currentEventId,
        value: this.anySelected.length > 0
          ? this.getMaxReverseTricastOdd(any) : this.currentBetData.value,
        betTypeId: this.anySelected.length > 0 ? 13 : this.currentBetData.betTypeId,
        odds: this.anySelected.length > 0
          ? this.getMaxReverseTricastOdd(any)
          : this.getMaxTricastOdd(first, second, third),
        maxOdds: this.anySelected.length > 0
          ? this.getMaxReverseTricastOdd(any)
          : this.getMaxTricastOdd(first, second, third),
        market: this.anySelected.length > 0 ? `${this.translations.greyhound_reverse} ${this.translations.greyhound_tricast.toLowerCase()} (${this.translations.general_combinations}: ${this.getReverseTricastCombs(this.anySelected.length)})` : this.formatTricastLabel(this.getCombinations(first, second, third)),
        roundNumber: this.anySelected.length > 0 ? this.currentRound : this.currentBetData.round,
        outcome: this.anySelected.length > 0 ? `${any}` : this.formatTricastOutcome(this.getUniqueCombinations(first, second, third)),
        combinations: this.anySelected.length > 0
          ? this.getReverseTricastCombs(this.anySelected.length)
          : this.getCombinations(first, second, third),
        reverseTricast: this.anySelected.length > 0,
      };
      if (bet.combinations > 1) {
        bet.odds = null;
      }
      //  const selectedRacers = [first, second, third];
      this.$emit('selectTricastOdd', bet);
      this.deselectAllBets();
      this.enableAllColumns();
      this.firstSelected = [];
      this.secondSelected = [];
      this.thirdSelected = [];
      this.anySelected = [];
    },
    getMaxTricastOdd(first, second, third) {
      const combinationOdds = [];
      const combinations = [];
      first.forEach((firstbet, j) => {
        second.forEach((secondbet, i) => {
          third.forEach((thirdbet, k) => {
            if (
              first[j] === second[i]
              || first[j] === third[k]
              || second[i] === third[k]
            ) {
              return;
            }
            combinations.push([first[j], second[i], third[k]]);
          });
        });
      });
      each(combinations, (combination) => {
        each(this.currentRoundOffer, (bets) => {
          if (bets.racers[0] === combination[0]
            && bets.racers[1] === combination[1]
            && bets.racers[2] === combination[2]) {
            combinationOdds.push(bets.value);
          }
        });
      });
      return Math.max(...combinationOdds); // / combinationOdds.length;
    },
    getMaxReverseTricastOdd(racers) {
      const tricasts = [];
      const runners = racers;
      const combinationOdds = [];
      for (let i = 0; i < runners.length; i += 1) {
        for (let j = i + 1; j < runners.length; j += 1) {
          for (let k = j + 1; k < runners.length; k += 1) {
            tricasts.push([runners[i], runners[j], runners[k]]);
            tricasts.push([runners[i], runners[k], runners[j]]);
            tricasts.push([runners[j], runners[i], runners[k]]);
            tricasts.push([runners[j], runners[k], runners[i]]);
            tricasts.push([runners[k], runners[i], runners[j]]);
            tricasts.push([runners[k], runners[j], runners[i]]);
          }
        }
      }
      each(tricasts, (combination) => {
        each(this.currentRoundOffer, (bets) => {
          if (bets.racers[0] === combination[0]
            && bets.racers[1] === combination[1]
            && bets.racers[2] === combination[2]) {
            combinationOdds.push(bets.value);
          }
        });
      });
      return Math.max(...combinationOdds); // / combinationOdds.length;
    },
    getReverseTricastCombs(num) {
      let comb = 1;
      if (num === 3) {
        comb = 6;
      }
      if (num === 4) {
        comb = 24;
      }
      if (num === 5) {
        comb = 60;
      }
      if (num === 6) {
        comb = 120;
      }
      if (num === 7) {
        comb = 210;
      }
      if (num === 8) {
        comb = 336;
      }
      return comb;
    },
    enableAllColumns() {
      each(this.tricastRows, (row) => {
        each(row, (singleRacer) => {
          // eslint-disable-next-line
          singleRacer.disabled = false;
        });
      });
    },
    disableAnyColumn() {
      each(this.tricastRows, (row) => {
        each(row, (singleRacer) => {
          if (singleRacer.column === 4) {
            // eslint-disable-next-line
            singleRacer.disabled = true;
          }
        });
      });
    },
    enableAnyColumn() {
      each(this.tricastRows, (row) => {
        each(row, (singleRacer) => {
          if (singleRacer.column === 4) {
            // eslint-disable-next-line
            singleRacer.disabled = true;
          }
        });
      });
    },
    disableTricastColumn() {
      each(this.tricastRows, (row) => {
        each(row, (singleRacer) => {
          if (singleRacer.column < 4) {
            // eslint-disable-next-line
            singleRacer.disabled = true;
          }
        });
      });
    },
    deselectAllBets() {
      each(this.tricastRows, (row) => {
        each(row, (singleRacer) => {
          // eslint-disable-next-line
          singleRacer.selected = false;
        });
      });
    },
    selectOdd(racer) {
      const racerSelected = racer;
      if (racer.column !== 4) {
        this.disableAnyColumn();
      }
      if (racer.column === 4) {
        this.disableTricastColumn();
      }
      each(this.tricastRows, (row) => {
        each(row, (singleRacer) => {
          if (singleRacer.column === racerSelected.column && singleRacer.id === racerSelected.id) {
            // eslint-disable-next-line
            singleRacer.selected = !singleRacer.selected;
            if (singleRacer.column === 1) {
              if (singleRacer.selected) {
                this.firstSelected.push(singleRacer);
              }
              if (!singleRacer.selected) {
                const updatedFirstSelected = reject(this.firstSelected, {
                  column: singleRacer.column,
                  id: singleRacer.id,
                });
                this.firstSelected = updatedFirstSelected;
              }
            }
            if (singleRacer.column === 2) {
              if (singleRacer.selected) {
                this.secondSelected.push(singleRacer);
              }
              if (!singleRacer.selected) {
                const updatedFirstSelected = reject(this.secondSelected, {
                  column: singleRacer.column,
                  id: singleRacer.id,
                });
                this.secondSelected = updatedFirstSelected;
              }
            }
            if (singleRacer.column === 3) {
              if (singleRacer.selected) {
                this.thirdSelected.push(singleRacer);
              }
              if (!singleRacer.selected) {
                const updatedFirstSelected = reject(this.thirdSelected, {
                  column: singleRacer.column,
                  id: singleRacer.id,
                });
                this.thirdSelected = updatedFirstSelected;
              }
            }
            if (singleRacer.column === 4) {
              if (singleRacer.selected) {
                this.anySelected.push(singleRacer);
              }
              if (!singleRacer.selected) {
                const updatedFirstSelected = reject(this.anySelected, {
                  column: singleRacer.column,
                  id: singleRacer.id,
                });
                this.anySelected = updatedFirstSelected;
              }
            }
          }
        });
      });
      this.selectedRacers = [];
      each(this.tricastRows, (row) => {
        each(row, (singleRacer) => {
          this.selectedRacers.push(singleRacer.selected);
        });
      });
      this.bettingUpdated = !this.bettingUpdated;
    },
    formatOdd(value) {
      if (value < 10) {
        return value.toFixed(2);
      }
      if (value >= 10 && value < 100) {
        return value.toFixed(1);
      }
      return value.toFixed(0);
    },
  },
  beforeMount() {
    const test = times(this.$attrs.racers, (i) => times(this.$attrs.tricastColumns, (j) => ({
      id: i + 1,
      column: j + 1,
      disabled: false,
      selected: false,
      label: 'icon icon-check-a',
    })));
    this.tricastRows = cloneDeep(test);
  },
};
</script>

<style lang="scss" scoped>
  .tricast-buttons-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: var(--card);
    padding: 0 8px 8px 8px;
    .header {
      font-family: 'Roboto', sans-serif;
      display: flex;
      font-size: 14px;
      color: var(--text-primary-2);
      padding: 8px 0px;
      margin-top: 16px;
      width: 100%;
      .header-item-empty {
        width: 17%;
      }
      .header-item {
        flex: 1;
        text-align: center;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
    }

    .buttons-row {
      display: flex;
      z-index: 0;

      .label {
        width: 17%;
        margin: 1px;
      }

      .button {
        font-family: 'Roboto', sans-serif;
        flex-grow: 1;
        width: 0;
        margin: 1px;
        &.disabled {
          user-select: none;
          pointer-events: none;
        }
      }
    }
    .add-bet-wrapper {
      padding: 8px 0px 8px 0px;
      width: 100%;
      z-index: 0;
      .button {
        font-family: Roboto;
      }
    }
  }

  @media (min-width: 660px) {
    .tricast-buttons-grid {
      .header {
        .header-item-empty {
          width: 180px;
      }
    }
      .buttons-row {
        .label {
          width: 180px;
        }
      }
    }
  }
</style>
